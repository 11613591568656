import React from 'react';

import { ArrowBigRightIcon } from 'lucide-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Container from 'components/_designSystem/_layout/Container';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/BigBlueButton/BigBlueButton.module.scss';
import Link from 'components/Link';

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  externalLink: PropTypes.bool,
};

const BigBlueButton = ({ children, href, externalLink }) => (
  <Container>
    <Grid className={styles.button}>
      <Link
        href={href}
        externalLink={externalLink}
        data-testid="big_blue_link_button"
        className={classNames(styles.list__company, 'flex flex-row text-white bg-blue-600 hover:bg-blue-700 group')}
      >
        {children}
        <ArrowBigRightIcon className="ml-1 h-5 w-5 scale-x-150 scale-y-75 fill-current text-white group-hover:bg-blue-700" />
      </Link>
    </Grid>
  </Container>
);

BigBlueButton.propTypes = propTypes;

export default BigBlueButton;
